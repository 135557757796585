import Controller from '@ember/controller';
import { UiSecondaryNavItemArgs, UiSecondaryNavItemChild } from 'vault-client/components/ui-secondary-nav-item';
import { BusinessEntityRole } from 'vault-client/types/graphql-types';
import { inject as service } from '@ember/service';
import { ModelFrom } from 'vault-client/utils/type-utils';
import BusinessesBusinessRoute from 'vault-client/routes/businesses/business';
import PermissionsService from 'vault-client/services/permissions';
import posthog from 'posthog-js';

export default class BusinessesBusinessController extends Controller {
	@service declare permissions: PermissionsService;
	@service applicationScope: any;
	@service media: any;
	declare model: ModelFrom<BusinessesBusinessRoute>;

	accountRoutePath: string = 'businesses.business.account';
	exposureRoute = 'businesses.business.exposure';

	get businessActiveProductLinks() {
		const activeProducts = [...(this.model?.Customer?.ActiveProducts ?? [])];
		const sortedActiveProducts = activeProducts.sort(function (a, b) {
			if (a.name < b.name) {
				return -1;
			}
			if (a.name > b.name) {
				return 1;
			}
			return 0;
		});
		const businessActiveProductLinks: UiSecondaryNavItemChild[] = sortedActiveProducts.map((product) => {
			return {
				label: product.name,
				link: this.exposureRoute,
				linkModel: product.slug,
				roles: [
					BusinessEntityRole.DairyProducer,
					BusinessEntityRole.GrainProducer,
					BusinessEntityRole.DairyProcessor,
					BusinessEntityRole.HogProducer,
				],
			};
		});
		businessActiveProductLinks.push({
			label: 'View All',
			link: 'businesses.business.exposures',
			roles: [
				BusinessEntityRole.DairyProducer,
				BusinessEntityRole.GrainProducer,
				BusinessEntityRole.DairyProcessor,
				BusinessEntityRole.HogProducer,
			],
		});
		return businessActiveProductLinks;
	}

	get directlyLinkToViewALLExposure() {
		return this.businessActiveProductLinks.length === 1 || this.businessActiveProductLinks.length >= 16 || this.media.isMobile;
	}

	get secondaryNavItems(): Omit<UiSecondaryNavItemArgs, 'uid'>[] {
		const businessRoles = this.model.Customer?.businessRoles ?? [];
		const hasNonDairyProducerBusinessRole = businessRoles.filter(
			(role: BusinessEntityRole) => !role.includes(BusinessEntityRole.DairyProducer),
		);
		const hasDairyProducerBusinessRole = businessRoles.filter((role: BusinessEntityRole) =>
			role.includes(BusinessEntityRole.DairyProducer),
		);
		const hasHogProducerBusinessRole = businessRoles.filter((role: BusinessEntityRole) => role.includes(BusinessEntityRole.HogProducer));
		const hasGrainProducerBusinessRole = businessRoles.filter((role: BusinessEntityRole) =>
			role.includes(BusinessEntityRole.GrainProducer),
		);

		const hasDrpPolicies = this.model.Customer?.DrpPolicies.length > 0;
		const hasLrpPolicies = this.model.Customer?.LrpPolicies.length > 0;
		const hasLgmPolicies = this.model.Customer?.LgmPolicies.length > 0;

		if (!this.model.Customer) {
			return [];
		}

		if (businessRoles.length == 0) {
			return [
				{
					label: 'Brokerage',
					link: 'businesses.business.brokerage',
					children: [
						{
							label: 'Accounts',
							link: `businesses.business.accounts`,
						},
						{
							label: 'Account',
							link: `businesses.business.account`,
							hidden: true,
						},
						{
							label: 'Positions',
							link: `businesses.business.positions`,
						},
						{
							label: 'Position',
							link: `businesses.business.position`,
							hidden: true,
						},
						{
							label: 'Transactions',
							link: `businesses.business.transactions`,
						},
						{
							label: 'Transaction',
							link: `businesses.business.transaction`,
							hidden: true,
						},
					],
				},
				{
					label: 'Reports',
					link: `businesses.business.reports`,
					children: [
						{
							label: 'Dairy Historical Percentiles',
							link: 'businesses.business.dairy-historical-percentiles',
							hidden: true,
						},
						{
							label: 'Historical Percentiles by Product',
							link: 'businesses.business.historical-percentiles-by-product',
							hidden: true,
						},
						{
							label: 'Butter Inventory Hedges',
							link: 'businesses.business.inventory-hedges-by-product',
							hidden: true,
						},
						{
							label: 'Strategic Inventory Model (SIM) - Butter ',
							link: 'businesses.business.butter-strategic-inventory-model',
							hidden: true,
						},
						{
							label: 'Strategic Buying Model (SBM) - Butter',
							link: 'businesses.business.butter-strategic-buying-model',
							hidden: true,
						},
						{
							label: 'Strategic Buying Model (SBM) - Nonfat Dry Milk ',
							link: 'businesses.business.nfdm-strategic-buying-model',
							hidden: true,
						},
						{
							label: 'Strategic Buying Model (SBM) - Class III ',
							link: 'businesses.business.class-iii-strategic-buying-model',
							hidden: true,
						},
						{
							label: 'Strategic Buying Model (SBM) - Class IV ',
							link: 'businesses.business.class-iv-strategic-buying-model',
							hidden: true,
						},
						{
							label: 'Strategic Buying Model (SBM) - Dry Whey ',
							link: 'businesses.business.dry-whey-strategic-buying-model',
							hidden: true,
						},
						{
							label: 'Cattle Historical Percentiles',
							link: 'businesses.business.cattle-historical-percentiles',
							hidden: true,
						},
						{
							label: 'Strategic Buying Model (SBM) - Cheese ',
							link: 'businesses.business.cheese-strategic-buying-model',
							hidden: true,
						},
						{
							label: 'Strategic Buying Model (SBM) - Cheese Block ',
							link: 'businesses.business.cheese-block-strategic-buying-model',
							hidden: true,
						},
						{
							label: 'Strategic Inventory Model (SIM) - Dry Whey ',
							link: 'businesses.business.dry-whey-strategic-inventory-model',
							hidden: true,
						},
						{
							label: 'Strategic Inventory Model (SIM) - Nonfat Dry Milk ',
							link: 'businesses.business.nfdm-strategic-inventory-model',
							hidden: true,
						},
						{
							label: 'Strategic Inventory Model (SIM) - Cheese Block ',
							link: 'businesses.business.cheese-block-strategic-inventory-model',
							hidden: true,
						},
						{
							label: 'Dairy Margin',
							link: 'businesses.business.dairy-margin',
							hidden: true,
						},
						{
							label: 'LRP Payment Calculator',
							link: 'businesses.business.lrp-calculator',
							hidden: true,
						},
						{
							label: 'DRP Performance by Plan Year',
							link: 'businesses.business.drp-performance-by-plan-year',
							hidden: true,
						},
						{
							label: 'DRP Performance by AIP',
							link: 'businesses.business.drp-performance-by-aip',
							hidden: true,
						},
						{
							label: 'DRP Performance by Sales Effective Date Month',
							link: 'businesses.business.drp-performance-by-sales-effective-date-month',
							hidden: true,
						},
						{
							label: 'End of month P/L values, combined with fees and commissions occured during the month.',
							link: 'businesses.business.pl-by-calendar-month.index',
							hidden: true,
						},
						{
							label: 'End of month P/L values, combined with fees and commissions occured during the month.',
							link: 'businesses.business.pl-by-calendar-month.show',
							hidden: true,
						},
						{
							label: 'A report allowing you to run scenarios (stress test) you combined Net P&L',
							link: 'businesses.business.price-scenarios',
							hidden: true,
						},
					],
				},
				{
					label: 'Settings',
					link: `businesses.business.business-settings`,
				},
			];
		}

		const baseItems: Omit<UiSecondaryNavItemArgs, 'uid'>[] = [
			{
				label: 'Dashboard',
				link: 'businesses.business.dashboard',
				roles: [BusinessEntityRole.DairyProducer, BusinessEntityRole.HogProducer],
				children: [
					{
						label: 'Dashboard',
						link: 'businesses.business.pig-dashboard',
						roles: [BusinessEntityRole.DairyProducer, BusinessEntityRole.HogProducer],
						hidden: true,
					},
				],
			},
			{
				label: 'Swine',
				link: 'businesses.business.swine',
				roles: [BusinessEntityRole.HogProducer],
				children: [
					{
						label: 'Weaned Pigs',
						link: 'businesses.business.weaned-pigs',
						roles: [BusinessEntityRole.HogProducer],
					},
					{
						label: 'Marketing',
						link: 'businesses.business.pig-marketing',
						roles: [BusinessEntityRole.HogProducer],
					},
					{
						label: 'Pig Cohorts',
						link: 'businesses.business.pig-cohorts',
						roles: [BusinessEntityRole.HogProducer],
					},
				],
			},
			{
				label: 'Milk',
				link: `businesses.business.milk`,
				children: [
					{
						label: 'Milk Production',
						link: `businesses.business.production`,
						roles: [BusinessEntityRole.DairyProducer],
					},
					{
						label: 'Milk Check Calculator',
						link: `businesses.business.milk-check`,
						roles: [BusinessEntityRole.DairyProducer],
					},
					{
						label: 'Milk Check Reconciliation',
						link: `businesses.business.milk-check-reconciliation`,
						roles: [BusinessEntityRole.DairyProducer],
					},
					{
						label: 'Premiums & Deductions',
						link: `businesses.business.milk-premiums`,
						roles: [BusinessEntityRole.DairyProducer],
					},
				],
				roles: [BusinessEntityRole.DairyProducer],
			},
			{
				label: 'Exposure',
				hidden: this.directlyLinkToViewALLExposure ? false : true,
				link: 'businesses.business.exposures',
				roles: [
					BusinessEntityRole.DairyProducer,
					BusinessEntityRole.GrainProducer,
					BusinessEntityRole.DairyProcessor,
					BusinessEntityRole.HogProducer,
				],
			},
			{
				label: 'Exposure',
				megaMenu: true,
				hidden: this.directlyLinkToViewALLExposure ? true : false,
				children: [
					{
						label: 'My Products',
						link: 'businesses.business.exposures',
						children: this.businessActiveProductLinks,
						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.HogProducer,
						],
					},
				],
				roles: [
					BusinessEntityRole.DairyProducer,
					BusinessEntityRole.GrainProducer,
					BusinessEntityRole.DairyProcessor,
					BusinessEntityRole.HogProducer,
				],
			},
			{
				label: 'Hedges',
				megaMenu: true,
				link: 'businesses.business.hedge-strategies.index',
				children: [
					{
						label: 'Strategies',

						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.HogProducer,
							BusinessEntityRole.CattleProducer,
						],
						children: [
							{
								label: 'View All',
								link: 'businesses.business.hedge-strategies',
								roles: [
									BusinessEntityRole.DairyProducer,
									BusinessEntityRole.GrainProducer,
									BusinessEntityRole.DairyProcessor,
									BusinessEntityRole.HogProducer,
									BusinessEntityRole.CattleProducer,
								],
								hidden: false,
							},
						],
					},
					{
						label: 'Brokerage',
						link: 'businesses.business.brokerage',
						children: [
							{
								label: 'Accounts',
								link: `businesses.business.accounts`,
								roles: [
									BusinessEntityRole.DairyProducer,
									BusinessEntityRole.GrainProducer,
									BusinessEntityRole.DairyProcessor,
									BusinessEntityRole.HogProducer,
									BusinessEntityRole.CattleProducer,
								],
							},
							{
								label: 'Account',
								link: `businesses.business.account`,
								roles: [
									BusinessEntityRole.DairyProducer,
									BusinessEntityRole.GrainProducer,
									BusinessEntityRole.DairyProcessor,
									BusinessEntityRole.HogProducer,
									BusinessEntityRole.CattleProducer,
								],
								hidden: true,
							},
							{
								label: 'Positions',
								link: `businesses.business.positions`,
								roles: [
									BusinessEntityRole.DairyProducer,
									BusinessEntityRole.GrainProducer,
									BusinessEntityRole.DairyProcessor,
									BusinessEntityRole.HogProducer,
									BusinessEntityRole.CattleProducer,
								],
							},
							{
								label: 'Position',
								link: `businesses.business.position`,
								roles: [
									BusinessEntityRole.DairyProducer,
									BusinessEntityRole.GrainProducer,
									BusinessEntityRole.DairyProcessor,
									BusinessEntityRole.HogProducer,
									BusinessEntityRole.CattleProducer,
								],
								hidden: true,
							},
							{
								label: 'Transactions',
								link: `businesses.business.transactions`,
								roles: [
									BusinessEntityRole.DairyProducer,
									BusinessEntityRole.GrainProducer,
									BusinessEntityRole.DairyProcessor,
									BusinessEntityRole.HogProducer,
									BusinessEntityRole.CattleProducer,
								],
							},
							{
								label: 'Transaction',
								link: `businesses.business.transaction`,
								roles: [
									BusinessEntityRole.DairyProducer,
									BusinessEntityRole.GrainProducer,
									BusinessEntityRole.DairyProcessor,
									BusinessEntityRole.HogProducer,
									BusinessEntityRole.CattleProducer,
								],
								hidden: true,
							},
						],
						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.HogProducer,
							BusinessEntityRole.CattleProducer,
						],
						hidden: this.media.isMobile ? true : false,
					},
					{
						label: 'Insurance',
						link: 'businesses.business.insurance',
						children: [
							{
								label: 'Overview',
								link: `businesses.business.insurance-overview`,
								roles: [BusinessEntityRole.DairyProducer, BusinessEntityRole.HogProducer],
							},
							{
								label: 'Quarters',
								link: `businesses.business.insurance-quarters`,
								roles: [BusinessEntityRole.DairyProducer],
								hidden: true,
							},
							{
								label: 'Policies',
								link: `businesses.business.insurance-policies`,
								roles: [BusinessEntityRole.DairyProducer, BusinessEntityRole.HogProducer, BusinessEntityRole.CattleProducer],
							},
							{
								label: 'Policy',
								link: `businesses.business.insurance-policy`,
								roles: [BusinessEntityRole.DairyProducer, BusinessEntityRole.HogProducer, BusinessEntityRole.CattleProducer],
								hidden: true,
							},
							{
								label: 'DRP Endorsements',
								link: `businesses.business.drp-insurance-endorsements`,
								roles: [
									BusinessEntityRole.DairyProducer,
									BusinessEntityRole.GrainProducer,
									BusinessEntityRole.DairyProcessor,
									BusinessEntityRole.HogProducer,
								],
								hidden: !hasDrpPolicies,
							},
							{
								label: 'DRP Endorsement',
								link: `businesses.business.drp-insurance-endorsement`,
								roles: [BusinessEntityRole.DairyProducer],
								hidden: true,
							},
							{
								label: 'LRP Endorsements',
								link: `businesses.business.lrp-insurance-endorsements`,
								roles: [
									BusinessEntityRole.DairyProducer,
									BusinessEntityRole.GrainProducer,
									BusinessEntityRole.DairyProcessor,
									BusinessEntityRole.HogProducer,
									BusinessEntityRole.CattleProducer,
								],
								hidden: !hasLrpPolicies,
							},
							{
								label: 'LRP Endorsement',
								link: `businesses.business.lrp-insurance-endorsement`,
								roles: [BusinessEntityRole.HogProducer, BusinessEntityRole.CattleProducer],
								hidden: true,
							},
							{
								label: 'LGM Endorsements',
								link: `businesses.business.lgm-insurance-endorsements`,
								roles: [
									BusinessEntityRole.DairyProducer,
									BusinessEntityRole.GrainProducer,
									BusinessEntityRole.DairyProcessor,
									BusinessEntityRole.HogProducer,
								],
								hidden: !hasLgmPolicies,
							},
							{
								label: 'LGM Endorsement',
								link: `businesses.business.lgm-insurance-endorsement`,
								roles: [BusinessEntityRole.HogProducer, BusinessEntityRole.DairyProducer],
								hidden: true,
							},
							{
								label: 'Documents',
								link: `businesses.business.insurance-documents`,
								roles: [BusinessEntityRole.DairyProducer, BusinessEntityRole.HogProducer, BusinessEntityRole.CattleProducer],
							},
							{
								label: 'DRP Quotes',
								link: 'https://quotes.ever.ag',
								isExternal: true,
								roles: [BusinessEntityRole.DairyProducer],
							},
							{
								label: 'LRP Quotes',
								link: 'https://quotes.ever.ag/lrp-quotes',
								isExternal: true,
								roles: [BusinessEntityRole.HogProducer, BusinessEntityRole.CattleProducer],
							},
						],
						roles: [BusinessEntityRole.DairyProducer, BusinessEntityRole.HogProducer, BusinessEntityRole.CattleProducer],
						hidden: this.media.isMobile ? true : false,
					},
				],
				tools: [
					{
						label: 'Swine Producer Price Scenarios',
						link: 'businesses.business.swine-price-scenarios',
						roles: [BusinessEntityRole.HogProducer],
					},
				],
				roles: [
					BusinessEntityRole.DairyProducer,
					BusinessEntityRole.GrainProducer,
					BusinessEntityRole.DairyProcessor,
					BusinessEntityRole.HogProducer,
					BusinessEntityRole.CattleProducer,
				],
			},
			{
				label: 'Brokerage',
				link: 'businesses.business.brokerage',
				children: [
					{
						label: 'Accounts',
						link: `businesses.business.accounts`,
						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.HogProducer,
							BusinessEntityRole.CattleProducer,
						],
					},
					{
						label: 'Account',
						link: `businesses.business.account`,
						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.HogProducer,
							BusinessEntityRole.CattleProducer,
						],
						hidden: true,
					},
					{
						label: 'Positions',
						link: `businesses.business.positions`,
						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.HogProducer,
							BusinessEntityRole.CattleProducer,
						],
					},
					{
						label: 'Position',
						link: `businesses.business.position`,
						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.HogProducer,
							BusinessEntityRole.CattleProducer,
						],
						hidden: true,
					},
					{
						label: 'Transactions',
						link: `businesses.business.transactions`,
						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.HogProducer,
							BusinessEntityRole.CattleProducer,
						],
					},
					{
						label: 'Transaction',
						link: `businesses.business.transaction`,
						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.HogProducer,
							BusinessEntityRole.CattleProducer,
						],
						hidden: true,
					},
				],
				roles: [
					BusinessEntityRole.DairyProducer,
					BusinessEntityRole.GrainProducer,
					BusinessEntityRole.DairyProcessor,
					BusinessEntityRole.HogProducer,
					BusinessEntityRole.CattleProducer,
				],
				hidden: this.media.isMobile ? false : true,
			},
			{
				label: 'Insurance',
				link: 'businesses.business.insurance',
				children: [
					{
						label: 'Overview',
						link: `businesses.business.insurance-overview`,
						roles: [BusinessEntityRole.DairyProducer],
					},
					{
						label: 'Quarters',
						link: `businesses.business.insurance-quarters`,
						roles: [BusinessEntityRole.DairyProducer],
						hidden: true,
					},
					{
						label: 'Policies',
						link: `businesses.business.insurance-policies`,
						roles: [BusinessEntityRole.DairyProducer, BusinessEntityRole.HogProducer, BusinessEntityRole.CattleProducer],
					},
					{
						label: 'Policy',
						link: `businesses.business.insurance-policy`,
						roles: [BusinessEntityRole.DairyProducer, BusinessEntityRole.HogProducer, BusinessEntityRole.CattleProducer],
						hidden: true,
					},
					{
						label: 'DRP Endorsements',
						link: `businesses.business.drp-insurance-endorsements`,
						roles: [BusinessEntityRole.DairyProducer],
					},
					{
						label: 'DRP Endorsement',
						link: `businesses.business.drp-insurance-endorsement`,
						roles: [BusinessEntityRole.DairyProducer],
						hidden: true,
					},
					{
						label: 'LRP Endorsements',
						link: `businesses.business.lrp-insurance-endorsements`,
						roles: [BusinessEntityRole.HogProducer, BusinessEntityRole.CattleProducer],
					},
					{
						label: 'LRP Endorsement',
						link: `businesses.business.lrp-insurance-endorsement`,
						roles: [BusinessEntityRole.HogProducer, BusinessEntityRole.CattleProducer],
						hidden: true,
					},
					{
						label: 'LGM Endorsements',
						link: `businesses.business.lgm-insurance-endorsements`,
						roles: [BusinessEntityRole.HogProducer, BusinessEntityRole.DairyProducer],
					},
					{
						label: 'LGM Endorsement',
						link: `businesses.business.lgm-insurance-endorsement`,
						roles: [BusinessEntityRole.HogProducer, BusinessEntityRole.DairyProducer],
						hidden: true,
					},
					{
						label: 'Documents',
						link: `businesses.business.insurance-documents`,
						roles: [BusinessEntityRole.DairyProducer, BusinessEntityRole.HogProducer, BusinessEntityRole.CattleProducer],
					},
					{
						label: 'DRP Quotes',
						link: 'https://quotes.ever.ag',
						isExternal: true,
						roles: [BusinessEntityRole.DairyProducer],
					},
					{
						label: 'LRP Quotes',
						link: 'https://quotes.ever.ag/lrp-quotes',
						isExternal: true,
						roles: [BusinessEntityRole.HogProducer, BusinessEntityRole.CattleProducer],
					},
				],
				roles: [BusinessEntityRole.DairyProducer, BusinessEntityRole.HogProducer, BusinessEntityRole.CattleProducer],
				hidden: this.media.isMobile ? false : true,
			},
			{
				label: 'Crops',
				link: `businesses.business.crops.index`,
				children: [
					{
						label: 'Crop Plans',
						link: `businesses.business.crop-plans`,
						roles: [BusinessEntityRole.GrainProducer],
					},
					{
						label: 'Plan',
						link: `businesses.business.crop-plan`,
						roles: [BusinessEntityRole.GrainProducer],
						hidden: true,
					},
					{
						label: 'Targets',
						link: `businesses.business.crop-targets`,
						roles: [BusinessEntityRole.GrainProducer],
					},
					{
						label: 'Fills',
						link: `businesses.business.crop-fills`,
						roles: [BusinessEntityRole.GrainProducer],
					},
          {
						label: 'Fields',
						link: `businesses.business.fields`,
						roles: posthog.isFeatureEnabled('Internal_Fields') ? [BusinessEntityRole.GrainProducer] : [],
					},
				],
				roles: [BusinessEntityRole.GrainProducer],
			},
			{
				label: 'Operations',
				link: 'businesses.business.operations',
				children: [
					{
						label: hasGrainProducerBusinessRole.length
							? 'Revenues'
							: hasDairyProducerBusinessRole.length
								? hasNonDairyProducerBusinessRole.length
									? 'Non-Production Revenues'
									: 'Non-Milk Revenues'
								: 'Non-Marketing Revenues',
						link: 'businesses.business.revenues',
						roles: [BusinessEntityRole.DairyProducer, BusinessEntityRole.GrainProducer, BusinessEntityRole.HogProducer],
					},
					{
						label: hasHogProducerBusinessRole.length
							? hasGrainProducerBusinessRole.length
								? 'Expenses'
								: 'Non-Feed Expenses'
							: 'Expenses',
						link: 'businesses.business.expenses',
						roles: [BusinessEntityRole.DairyProducer, BusinessEntityRole.GrainProducer, BusinessEntityRole.HogProducer],
					},
				],
				roles: [BusinessEntityRole.DairyProducer, BusinessEntityRole.GrainProducer, BusinessEntityRole.HogProducer],
			},
			{
				label: 'Reports',
				link: `businesses.business.reports`,
				children: [
					{
						label: 'Dairy Historical Percentiles',
						link: 'businesses.business.dairy-historical-percentiles',
						hidden: true,
						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.HogProducer,
							BusinessEntityRole.CattleProducer,
						],
					},
					{
						label: 'Historical Percentiles by Product',
						link: 'businesses.business.historical-percentiles-by-product',
						hidden: true,
						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.HogProducer,
							BusinessEntityRole.CattleProducer,
						],
					},
					{
						label: 'Butter Inventory Hedges',
						link: 'businesses.business.inventory-hedges-by-product',
						hidden: true,
						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.HogProducer,
							BusinessEntityRole.CattleProducer,
						],
					},
					{
						label: 'Strategic Inventory Model (SIM) - Butter ',
						link: 'businesses.business.butter-strategic-inventory-model',
						hidden: true,
						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.HogProducer,
							BusinessEntityRole.CattleProducer,
						],
					},
					{
						label: 'Strategic Buying Model (SBM) - Butter',
						link: 'businesses.business.butter-strategic-buying-model',
						hidden: true,
						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.HogProducer,
							BusinessEntityRole.CattleProducer,
						],
					},
					{
						label: 'Strategic Buying Model (SBM) - Nonfat Dry Milk ',
						link: 'businesses.business.nfdm-strategic-buying-model',
						hidden: true,
						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.HogProducer,
							BusinessEntityRole.CattleProducer,
						],
					},
					{
						label: 'Strategic Buying Model (SBM) - Class III ',
						link: 'businesses.business.class-iii-strategic-buying-model',
						hidden: true,
						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.HogProducer,
							BusinessEntityRole.CattleProducer,
						],
					},
					{
						label: 'Strategic Buying Model (SBM) - Class IV ',
						link: 'businesses.business.class-iv-strategic-buying-model',
						hidden: true,
						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.HogProducer,
							BusinessEntityRole.CattleProducer,
						],
					},
					{
						label: 'Strategic Buying Model (SBM) - Dry Whey ',
						link: 'businesses.business.dry-whey-strategic-buying-model',
						hidden: true,
						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.HogProducer,
							BusinessEntityRole.CattleProducer,
						],
					},
					{
						label: 'Cattle Historical Percentiles',
						link: 'businesses.business.cattle-historical-percentiles',
						hidden: true,
						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.HogProducer,
							BusinessEntityRole.CattleProducer,
						],
					},
					{
						label: 'Strategic Buying Model (SBM) - Cheese ',
						link: 'businesses.business.cheese-strategic-buying-model',
						hidden: true,
						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.HogProducer,
							BusinessEntityRole.CattleProducer,
						],
					},
					{
						label: 'Strategic Buying Model (SBM) - Cheese Block ',
						link: 'businesses.business.cheese-block-strategic-buying-model',
						hidden: true,
						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.HogProducer,
							BusinessEntityRole.CattleProducer,
						],
					},
					{
						label: 'Strategic Inventory Model (SIM) - Dry Whey ',
						link: 'businesses.business.dry-whey-strategic-inventory-model',
						hidden: true,
						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.HogProducer,
							BusinessEntityRole.CattleProducer,
						],
					},
					{
						label: 'Strategic Inventory Model (SIM) - Nonfat Dry Milk ',
						link: 'businesses.business.nfdm-strategic-inventory-model',
						hidden: true,
						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.HogProducer,
							BusinessEntityRole.CattleProducer,
						],
					},
					{
						label: 'Strategic Inventory Model (SIM) - Cheese Block ',
						link: 'businesses.business.cheese-block-strategic-inventory-model',
						hidden: true,
						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.HogProducer,
							BusinessEntityRole.CattleProducer,
						],
					},
					{
						label: 'Dairy Margin',
						link: 'businesses.business.dairy-margin',
						hidden: true,
						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.HogProducer,
							BusinessEntityRole.CattleProducer,
						],
					},
					{
						label: 'LRP Payment Calculator',
						link: 'businesses.business.lrp-calculator',
						hidden: true,
						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.HogProducer,
							BusinessEntityRole.CattleProducer,
						],
					},
					{
						label: 'DRP Performance by Plan Year',
						link: 'businesses.business.drp-performance-by-plan-year',
						hidden: true,
						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.HogProducer,
							BusinessEntityRole.CattleProducer,
						],
					},
					{
						label: 'DRP Performance by AIP',
						link: 'businesses.business.drp-performance-by-aip',
						hidden: true,
						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.HogProducer,
							BusinessEntityRole.CattleProducer,
						],
					},
					{
						label: 'DRP Performance by Sales Effective Date Month',
						link: 'businesses.business.drp-performance-by-sales-effective-date-month',
						hidden: true,
						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.HogProducer,
							BusinessEntityRole.CattleProducer,
						],
					},
					{
						label: 'End of month P/L values, combined with fees and commissions occured during the month.',
						link: 'businesses.business.pl-by-calendar-month.index',
						hidden: true,
						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.HogProducer,
							BusinessEntityRole.CattleProducer,
						],
					},
					{
						label: 'End of month P/L values, combined with fees and commissions occurred during the month.',
						link: 'businesses.business.pl-by-calendar-month.show',
						hidden: true,
						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.HogProducer,
							BusinessEntityRole.CattleProducer,
						],
					},
					{
						label: 'A report allowing you to run scenarios (stress test) your combined Net P&L',
						link: 'businesses.business.price-scenarios',
						roles: [BusinessEntityRole.DairyProducer],
						hidden: true,
					},
				],
				roles: [
					BusinessEntityRole.DairyProducer,
					BusinessEntityRole.GrainProducer,
					BusinessEntityRole.DairyProcessor,
					BusinessEntityRole.HogProducer,
					BusinessEntityRole.CattleProducer,
				],
			},
			// {
			// 	label: 'Calculators',
			// 	roles: [
			// 		BusinessEntityRole.DairyProducer,
			// 		BusinessEntityRole.GrainProducer,
			// 		BusinessEntityRole.DairyProcessor,
			// 		BusinessEntityRole.HogProducer,
			// 		BusinessEntityRole.CattleProducer,
			// 	],
			// 	children: [
			// 		{
			// 			label: 'DRP Quotes',
			// 			isExternal: true,
			// 			link: 'https://quotes.ever.ag/drp-quotes?practiceCode=803',
			// 			roles: [
			// 				BusinessEntityRole.DairyProducer,
			// 				BusinessEntityRole.GrainProducer,
			// 				BusinessEntityRole.DairyProcessor,
			// 				BusinessEntityRole.HogProducer,
			// 				BusinessEntityRole.CattleProducer,
			// 			],
			// 		},
			// 		{
			// 			label: 'LRP Quotes',
			// 			isExternal: true,
			// 			link: 'https://quotes.ever.ag/lrp-quotes',
			// 			roles: [
			// 				BusinessEntityRole.DairyProducer,
			// 				BusinessEntityRole.GrainProducer,
			// 				BusinessEntityRole.DairyProcessor,
			// 				BusinessEntityRole.HogProducer,
			// 				BusinessEntityRole.CattleProducer,
			// 			],
			// 		},
			// 		{
			// 			label: 'Feed Basis',
			// 			isExternal: true,
			// 			link: 'https://quotes.ever.ag/feed-basis',
			// 			roles: [
			// 				BusinessEntityRole.DairyProducer,
			// 				BusinessEntityRole.GrainProducer,
			// 				BusinessEntityRole.DairyProcessor,
			// 				BusinessEntityRole.HogProducer,
			// 				BusinessEntityRole.CattleProducer,
			// 			],
			// 		},
			// 		{
			// 			label: 'Cheese Price',
			// 			isExternal: true,
			// 			link: 'https://www.ever.ag/cheese-calculator',
			// 			roles: [
			// 				BusinessEntityRole.DairyProducer,
			// 				BusinessEntityRole.GrainProducer,
			// 				BusinessEntityRole.DairyProcessor,
			// 				BusinessEntityRole.HogProducer,
			// 				BusinessEntityRole.CattleProducer,
			// 			],
			// 		},
			// 		{
			// 			label: 'Class III/IV Prices',
			// 			isExternal: true,
			// 			link: 'https://www.ever.ag/class-iii-iv-calculator',
			// 			roles: [
			// 				BusinessEntityRole.DairyProducer,
			// 				BusinessEntityRole.GrainProducer,
			// 				BusinessEntityRole.DairyProcessor,
			// 				BusinessEntityRole.HogProducer,
			// 				BusinessEntityRole.CattleProducer,
			// 			],
			// 		},
			// 		{
			// 			label: `Energy Corrected Milk & Feed Efficiency`,
			// 			isExternal: true,
			// 			link: 'https://www.ever.ag/energy-corrected-milk-and-feed-efficiency-calculator',
			// 			roles: [
			// 				BusinessEntityRole.DairyProducer,
			// 				BusinessEntityRole.GrainProducer,
			// 				BusinessEntityRole.DairyProcessor,
			// 				BusinessEntityRole.HogProducer,
			// 				BusinessEntityRole.CattleProducer,
			// 			],
			// 		},
			// 	],
			// },
			{
				label: 'Settings',
				link: `businesses.business.settings`,
				children: [
					{
						label: 'Business Settings',
						link: 'businesses.business.business-settings',
						roles: [
							BusinessEntityRole.DairyProducer,
							BusinessEntityRole.DairyProcessor,
							BusinessEntityRole.GrainProducer,
							BusinessEntityRole.HogProducer,
							BusinessEntityRole.CattleProducer,
						],
					},
					{
						label: 'Stages',
						link: 'businesses.business.animal-groups',
						roles: [BusinessEntityRole.HogProducer],
					},
					{
						label: 'Contracts',
						link: 'businesses.business.production-contracts',
						roles: [BusinessEntityRole.HogProducer],
					},
					{
						label: 'Locations',
						link: 'businesses.business.locations',
						roles: [BusinessEntityRole.DairyProducer, BusinessEntityRole.DairyProcessor],
					},
				],
				roles: [
					BusinessEntityRole.DairyProducer,
					BusinessEntityRole.DairyProcessor,
					BusinessEntityRole.GrainProducer,
					BusinessEntityRole.HogProducer,
					BusinessEntityRole.CattleProducer,
				],
			},
		];

		if (businessRoles.includes(BusinessEntityRole.HogProducer)) {
			const idx = baseItems.findIndex((item) => item.label === 'Swine') + 1;
			baseItems.insertAt(idx, {
				label: 'Feed',
				link: 'businesses.business.livestock-feed',
				roles: [BusinessEntityRole.HogProducer],
				children: [
					{
						label: 'Feed Overview',
						link: 'businesses.business.feed-overview',
						roles: [BusinessEntityRole.HogProducer],
					},
					{
						label: 'Feed Overview Detail',
						link: 'businesses.business.livestock-feed-overview-detail',
						roles: [BusinessEntityRole.HogProducer],
						hidden: true,
					},
					{
						label: 'Feed Usage',
						link: 'businesses.business.livestock-feed-usage',
						roles: [BusinessEntityRole.HogProducer],
					},
					{
						label: 'Total Feed Expenses',
						link: 'businesses.business.livestock-feed-expenses',
						roles: [BusinessEntityRole.HogProducer],
					},
					{
						label: 'Feed Fills',
						link: 'businesses.business.feed-fills',
						roles: [BusinessEntityRole.HogProducer],
					},
					// {
					// 	label: 'Feed Orders',
					// 	link: 'businesses.business.livestock-feed-orders',
					// 	roles: [BusinessEntityRole.HogProducer],
					// },
					// {
					// 	label: 'Feed Purchases',
					// 	link: 'businesses.business.livestock-feed-purchases',
					// 	roles: [BusinessEntityRole.HogProducer],
					// },
					{
						label: 'Feed Ingredient',
						link: 'businesses.business.livestock-feed-ingredient',
						roles: [BusinessEntityRole.HogProducer],
						hidden: true,
					},
				],
			});
		} else if (businessRoles.includes(BusinessEntityRole.DairyProducer)) {
			const idx = baseItems.findIndex((item) => item.label === 'Milk') + 1;
			baseItems.insertAt(idx, {
				label: 'Feed',
				link: 'businesses.business.feed',
				roles: [BusinessEntityRole.DairyProducer],
				children: [
					{
						label: 'Feed Overview',
						link: 'businesses.business.feed-overview',
						roles: [BusinessEntityRole.DairyProducer],
					},
					{
						label: 'Feed Overview Detail',
						link: 'businesses.business.feed-overview-detail',
						roles: [BusinessEntityRole.DairyProducer],
						hidden: true,
					},
					{
						label: 'Feed Usage',
						link: 'businesses.business.feed-usage',
						roles: [BusinessEntityRole.DairyProducer],
					},
					{
						label: 'Fixed Costs',
						link: 'businesses.business.feed-costs',
						roles: [BusinessEntityRole.DairyProducer],
					},
					{
						label: 'Total Feed Expenses',
						link: 'businesses.business.livestock-feed-expenses',
						roles: [BusinessEntityRole.DairyProducer],
					},
					{
						label: 'Feed Fills',
						link: 'businesses.business.feed-fills',
						roles: [BusinessEntityRole.DairyProducer],
					},
					{
						label: 'Feed Ingredient',
						link: 'businesses.business.feed-ingredient',
						roles: [BusinessEntityRole.DairyProducer],
						hidden: true,
					},
				],
			});
		}

		const filteredTopLevelItems = baseItems.filter((item) => {
			return item.roles?.some((role) => {
				return businessRoles.includes(role);
			});
		});

		return filteredTopLevelItems.map((item) => {
			this.setLinkModel(item);
			if (item.children) {
				//@ts-ignore

				item.children = item.children
					?.filter((subitem: any) => {
						return subitem.roles?.some((role: any) => {
							return businessRoles.includes(role);
						});
					})
					.map((child) => {
						this.setLinkModel(child);
						if (child.children) {
							child.children = child.children
								?.filter((subitem: any) => {
									return subitem.roles?.some((role: any) => {
										return businessRoles.includes(role);
									});
								})
								.map((subchild) => {
									this.setLinkModel(subchild);
									return subchild;
								});
						}
						return child;
					});
			}

			// Filtering the tools by roles
			if (item.tools) {
				item.tools = item.tools
					?.filter((subitem: any) => {
						return subitem.roles?.some((role: any) => {
							return businessRoles.includes(role);
						});
					})
					.map((child) => {
						this.setLinkModel(child);
						return child;
					});
			}
			return item;
		});
	}

	get previous(): { label?: string; route?: string; model?: string } {
		const canViewMultipleOrgs = this.permissions.showOrganizations;
		const canViewOneOrg = this.permissions.showOrganization;
		const canViewMultipleCustomers = this.permissions.showCustomers;
		// const canViewOneCustomer = this.permissions.showCustomer;

		if (canViewMultipleOrgs || canViewOneOrg) {
			// no model needed as organizationId always set in applicationScope (if have org / multi bus)
			// when have org / multi bus dashboard, revert back to leveraging dynamic route segments
			return {
				label: this.model?.Customer?.Organization?.name,
				route: 'index',
			};
		} else if (canViewMultipleCustomers) {
			return {
				label: 'All Businesses',
				route: 'organizations.organization.businesses',
				model: this.applicationScope.organizationId,
			};
		} else {
			return {};
		}
	}

	get current(): { label?: string; route?: string; model?: string } {
		const customerName = this.model.Customer?.name;
		const customerId = this.model.businessId;

		if (customerName && customerId) {
			return {
				label: this.model.Customer.name,
				route: 'businesses.business.dashboard',
				model: this.model.Customer.id,
			};
		} else {
			return {};
		}
	}

	setLinkModel(item: UiSecondaryNavItemArgs | UiSecondaryNavItemChild) {
		if (item.linkModels) {
			item.linkModels.unshiftObject(this.model.businessId);
		} else if (item.linkModel) {
			item.linkModels = [this.model.businessId, item.linkModel];
			item.linkModel = undefined;
		} else {
			item.linkModel = this.model.businessId;
		}
		return item;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business': BusinessesBusinessController;
	}
}
